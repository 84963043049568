import React from 'react'
import styled from 'styled-components'

import { Layout } from '../../components/layout'
import { Footer } from '../../components/footer'
import { Teaser } from '../../components/teaser'

import { Image } from '../../components/common/image'
import { Grid, Row, Col } from '../../components/common/grid'
import { Space } from '../../components/common/space'
import { H4 } from '../../components/common/heading'

const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  color: #69707b;
  text-align: justify;
  margin-bottom: 10px;
`

const title = 'QuickMix Energy Shakes'
const description = 'Isotonische Energie-Getränke ohne künstliche Zusatzstoffe'
const keywords = ''

const PropteinRiegel = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="quick_mix" />

    <Grid>
      <Row centered>
        <Col xs={12} md={9}>
          <div>
            <Space bottom={20}>
              <H4>QuickMix Protein- und Energy-Shakes (35g / 350g)</H4>
            </Space>
            <p>
              Hergestellt auf rein pflanzlicher Basis sind die Chimpanzee Energy
              und Protein Shakes auch für Veganer geeignet. Mit Wasser, Milch
              oder Joghurt, warm oder kalt, als Brei oder Getränk — vielfältige
              Möglichkeiten für jede Situation.
            </p>

            <Space top={50} bottom={20}>
              <H4>Geschmacksrichtungen</H4>
            </Space>

            <List>
              <ListItem>Protein Shake: Kakao & Ahornsirup</ListItem>
              <ListItem>Energy Shake: Honig, Cerealien & Kaka</ListItem>
            </List>
          </div>
        </Col>
      </Row>

      <Space top={50} bottom={20}>
        <Row centered>
          <Col xs={12} md={9}>
            <H4>Produktbeispiele</H4>
          </Col>
        </Row>
      </Space>

      <Row centered>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="quickmix_protein" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="quickmix_energy" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
        </Col>
      </Row>
    </Grid>

    <Footer bottom={50} />
  </Layout>
)

export default PropteinRiegel
